import React, { memo, useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { customShare } from "utils/weixin";
import { DataList, EnterLoading, Error } from "components/basic";
import { initHealthTest, changeTestList } from "./store/actionCreators";
import useBack from "hooks/useBack";
import "./index.scss";

import { APP_DOWNLOAD_URL, LOGO } from "common/constant";
import { Modal } from "antd-mobile";

const HealthTest: React.FC<{
  rows?: any[];
}> = ({ rows }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // 自定义分享内容
    customShare({
      title: `健康自测-丁丁好医`,
      desc: "国际通用的男科医学指南自测量表，自测评估你的健康状况",
      imgUrl:LOGO
    });

    if (!rows) {
      dispatch(
        initHealthTest((error?: string) => {
          if (error) {
            setError(error);
          }
          setLoading(false);
        })
      );
    }
  }, [dispatch, rows]);

  useBack(() => dispatch(changeTestList()));

  /**
   * 显示APP下载
   */
 const showAppDownloadTips = useCallback((title, msg) => {
  Modal.alert(
    title,
    <div style={{
      color: "#333",
      fontSize: "13px",
      textAlign: "left",
    }}>
      <div style={{
        lineHeight: "1.4em",
      }}>{msg}</div>
      <div>
        <div style={{
          color: "#F17A7A",
          marginTop: "10px",
        }}>下载提示</div>
        <div>1.点击“确定”按钮，进入官方下载页面根据提示下载“丁丁好医”APP；</div>
        <div>2.安卓手机：应用宝搜索“丁丁好医”；</div>
        <div>3.苹果手机：App Store搜索“丁丁好医”；</div>
      </div>
    </div>,
    [
      { text: "取消", style: "default" },
      {
        text: "确定",
        onPress: async () => {
          window.location.href = APP_DOWNLOAD_URL;
          return;
        },
      },
    ]
  )
  },[]);

  if (error) {
    return <Error error={error} />;
  } else {
    if (loading) {
      return <EnterLoading />;
    } else {
      return (
        <div className="Screen-HealthTest">
          <DataList
            rows={rows}
            renderRow={(item: any, index) => {
              return (
                <div className="test-item" key={index} onClick={() => {
                  showAppDownloadTips("功能提示", "网页端暂不支持该功能，请下载APP后再使用");
                  // if(item.is_brand_custom === 1){
                  //   history.push(`/brand-test/${item.id}`);
                  // }else{
                  //   history.push(`/test/${item.id}`);
                  // }
                }}>
                  <div className="item-cover" style={{
                    backgroundImage: "url(" + item.test_img + ")",
                  }}></div>

                  <div className="item-main">
                    <div className="item-title">{item.test_title}</div>
                    <div className="item-more Text-Line__1">{item.test_alias}</div>
                  </div>

                  <div className="item-btn">
                    开始测评
                  </div>
                </div>
              );
            }}
          />
        </div>
      );
    }
  }
};

const mapStateToProps = (state: any) => ({
  rows: state.getIn(["test", "testList"]),
});

export default connect(mapStateToProps)(memo(HealthTest));
